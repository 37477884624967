import {
  Typography,
  Box,
  Avatar,
  Card,
  CardContent,
  styled,
  Button,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  FormControl,
  Tooltip,
  IconButton
} from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { useContext, useEffect, useRef, useState } from "react";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import { AuthContext } from "src/contexts/FirebaseAuthContext";
import useAgentStore from "src/hooks/UseStore";
import DownloadReport from "./DownloadReport";
import {
  addDays,
  addHours,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { CustomProvider, DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import esES from "rsuite/locales/es_ES";
import { tzDate, dayStart, dayEnd } from "@formkit/tempo";
import InfoIcon from "@mui/icons-material/Info";

dayjs.extend(utc);
dayjs.extend(timezone);

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.gradients.orange1};
        color:  ${theme.palette.primary.contrastText};
  `
);

function BankFees() {
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const agentType = useAgentStore((state) => state.agentType);
  const [data, setData] = useState({});
  const [openType, setOpenMenuType] = useState(false);
  const [period, setPeriod] = useState("all");
  const [type, setType] = useState();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const [dateRange, setDateRange] = useState([new Date(dayjs.tz(new Date(), "America/El_Salvador").set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)),new Date(dayjs.tz(new Date(), "America/El_Salvador").set("hour", 23).set("minute", 59).set("second", 59).set("millisecond", 999))]);
  const [dateRange, setDateRange] = useState([]);
  let id_usuario;
  if (agentType === "Agente") {
    id_usuario = user?.id_agente;
  } else if (agentType === "SubConcesionario") {
    id_usuario = user?.id_subconcesionario;
  } else if (agentType === "Concesionario") {
    id_usuario = user?.id_concesionario;
  }
  const types = [
    {
      value: 1,
      text: "Recargas",
    },
    {
      value: 3,
      text: "Premios",
    },
    {
      value: 7,
      text: "GGR",
    },
    {
      value: 6,
      text: "GGR PV",
    },
  ];


  useEffect(() => {
    if (dateRange.length === 2 && type !== undefined) {
      fetch(
          `/api/homecomissions?id_autor=${id_usuario}&startDate=${dateRange[0].toISOString()}&endDate=${dateRange[1].toISOString()}&type=${type}`
      )
          .then((response) => response.json())
          .then((rest) => {
              setData(rest.commissionsByType);
          })
          .catch((error) => {
              console.error("Error fetching commissions:", error);
          });
  } else {
    setData("0.00");
  }
  }, [dateRange, type]);




  const columns = [
    { header: "ID Transacción", key: "id_transaccion", width: 25 },
    { header: "ID Comisión", key: "id_comision", width: 25 },
    { header: "Fecha", key: "fecha", width: 20 },
    { header: "Valor Comisión", key: "valor_comision", width: 15 },
    { header: "Valor Transacción", key: "valor_transaccion", width: 15 },
    { header: "ID Autor", key: "id_autor", width: 15 },
    { header: "Tipo Comision", key: "tipo_comision", width: 15 },
  ];

  const actionRef1 = useRef(null);

  const handleChangeDateRange = (event) => {
    const [startDate, endDate] = event;
    const timeZone = "America/El_Salvador";
    const newStartDate = tzDate(dayStart(startDate), timeZone)
    const newEndDate = tzDate(dayEnd(endDate), timeZone)
    setDateRange([newStartDate, newEndDate]);
  };

  const isSameDate = (date1, date2) => date1.toISOString() === date2.toISOString();

  const renderDateRangeLabel = (value) => {
    if (!value || value.length !== 2) return "Rango de fechas";
    const [startDate, endDate] = value;
    for (const range of predefinedRanges) {
      const [rangeStart, rangeEnd] = range.value;
      if (
        (isSameDate(startOfDay(startDate), rangeStart) &&
          isSameDate(endOfDay(endDate), rangeEnd)) ||
        (isSameDate(
          startDate,
          dayjs.tz(rangeStart, "America/El_Salvador")
        ) &&
          isSameDate(endDate, dayjs.tz(rangeEnd, "America/El_Salvador")))
      ) {
        return range.label;
      }
    }
    return `${startDate.toLocaleDateString()} a ${endDate.toLocaleDateString()}`;
  };

  const predefinedRanges = [
    {
      label: "Hoy",
      value: [
        new Date(),
        new Date(),
      ],
    },
    {
      label: "Ayer",
      value: [
        addDays(new Date(), -1),
        addDays(new Date(), -1),
      ],
    },
    {
      label: "Esta semana",
      value: [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
    },
    {
      label: "Semana pasada",
      value: [
        startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
        endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 })
      ],
    },
    {
      label: "Este mes",
      value: [
        startOfMonth(new Date()),
        endOfMonth(new Date()),
      ],
    },
    {
      label: "Mes pasado",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
    },
    {
      label: "Este año",
      value: [startOfYear(new Date()), endOfYear(new Date())],
    },
  ];

  return (
    <Card
      sx={{
        background: `${theme.colors.gradients.black2}`,
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <AvatarWrapper>
                <CurrencyExchangeIcon sx={{ color: "lightgreen" }} />
              </AvatarWrapper>
              <Typography
                sx={{ ml: 1 }}
                variant="body1"
                color="white"
                component="div"
              >
                Comisiones
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box sx={{ width: "55%", marginLeft: "30px" }}>
                <FormControl fullWidth>
                  <CustomProvider locale={esES} theme={"dark"}>
                    <DateRangePicker
                      size="sm"
                      placement="bottom"
                      appearance="subtle"
                      editable={false}
                      oneTap={false}
                      format="dd-MM-yy"
                      showOneCalendar={isMobile}
                      showHeader={false}
                      placeholder="Rango de fechas"
                      defaultValue={dateRange}
                      weekStart={1}
                      ranges={predefinedRanges}
                      onChange={(value) => {
                        if (value) {
                          handleChangeDateRange(value);
                        } 
                        else {
                          setDateRange([]);
                          setData("0.00");
                        }
                        // else {
                        //   handleChangeDateRange([
                        //     new Date(dayjs.tz(new Date(), "America/El_Salvador").set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)),
                        //     new Date(dayjs.tz(new Date(), "America/El_Salvador").set("hour", 23).set("minute", 59).set("second", 59).set("millisecond", 999))
                        //   ]);
                        // }
                      }}
                      renderValue={renderDateRangeLabel}
                    />
                  </CustomProvider>
                </FormControl>
              </Box>
              <Button
                size="small"
                variant="outlined"
                ref={actionRef1}
                onClick={() => setOpenMenuType(true)}
                endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
              >
                {types.find((t) => t.value === type)?.text}
              </Button>
            </Box>
            <Menu
              disableScrollLock
              anchorEl={actionRef1.current}
              onClose={() => setOpenMenuType(false)}
              open={openType}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {types.map((_type) => (
                <MenuItem
                  key={_type.value}
                  onClick={() => {
                    setType(_type.value);
                    setOpenMenuType(false);
                  }}
                >
                  {_type.text}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            ml: -1.5,
            py: 2,
            justifyContent: "center",
          }}
        >
           <Tooltip
            title={type === 7 || type === 6 ? "Los valores pueden variar" : ""}
            arrow
          >
             <Typography variant="h2" color="white">
             {(() => {
                let result = 'L 0.00';
                switch (type) {
                  case 1:
                    result = `L ${Number(data?.deposits?.sum || 0).toFixed(2)}`;
                    break;
                  case 3:
                    result = `L ${Number(data?.withdrawals?.sum || 0).toFixed(2)}`;
                    break;
                  case 7:
                    result = `L ${Number(data?.bets?.sum || 0).toFixed(2)}`;
                    break;
                  case 6:
                    result = `L ${Number(data?.agent_bets?.sum || 0).toFixed(2)}`;
                    break;
                  default:
                    result = 'L 0.00';
                }
                return result;
            })()}
              </Typography>
              {(type === 7 || type === 6) && (
                <IconButton size="small" sx={{ ml: 4 }}>
                  <InfoIcon sx={{ color: "white" }} />
                </IconButton>
              )}
          </Tooltip>
        </Box>
        <DownloadReport
          id={id_usuario}
          paramKey={"id_autor"}
          apiEndpoint="/api/homecomissions"
          worksheetName="Comisiones"
          fileName="comisiones"
          columns={columns}
          type={type}
          isRange={true}
          startDate={dateRange.length > 0 ? dateRange[0].toISOString() : null}
          endDate={dateRange.length > 0 ? dateRange[1].toISOString() : null}
        />
      </CardContent>
    </Card>
  );
}

export default BankFees;
